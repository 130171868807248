import axios from "axios";
import "firebase/auth";
import "firebase/firestore";
import store from "../store";

axios.interceptors.request.use(async (config) => {
  // console.log("■", config.url, store.state.userIdToken)

  if (store.state.userIdToken) {
    config.headers.common["X-Authorization"] =
      "Bearer " + store.state.userIdToken;
  }
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default {};
