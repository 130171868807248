import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate"; // 使用する機能
import ja from "vee-validate/dist/locale/ja.json"; // エラーメッセージの日本語化用
// VeeValidate2と違って、公式が提供しているバリデーションルールは自分で入れる必要があるので、全ルールをインポートする
// (公式のルールを使わない場合は記述しなくて良い）
// 公式のルールはこちら
// https://logaretm.github.io/vee-validate/guide/rules.html#rules
import * as originalRules from "vee-validate/dist/rules";
import {
  alpha,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  dimensions,
  email,
  excluded,
  ext,
  image,
  is,
  is_not,
  length,
  max,
  max_value,
  mimes,
  min,
  min_value,
  numeric,
  regex,
  required,
  required_if,
  size,
} from "vee-validate/dist/rules";
import Vue from "vue";
import * as CustomRules from "./validation-custom-rules.js";

// バリデーションルール
extend("digits", digits);
extend("dimensions", dimensions);
extend("ext", ext);
extend("image", image);
extend("is", is);
extend("is_not", is_not);
extend("length", length);
extend("max", max);
extend("max_value", max_value);
extend("mimes", mimes);
extend("min", min);
extend("min_value", min_value);
extend("excluded", excluded);
extend("numeric", numeric);
extend("regex", regex);
extend("required_if", required_if);
extend("size", size);
extend("required", required);
extend("email", email);
extend("alpha", alpha);
extend("alpha_dash", alpha_dash);
extend("alpha_num", alpha_num);
extend("alpha_spaces", alpha_spaces);
extend("between", between);
extend("confirmed", confirmed);

for (let rule in originalRules) {
  extend(rule, originalRules[rule]);
}
for (const rule in CustomRules) {
  extend(rule, CustomRules[rule]);
}
// バリデーションメッセージの日本語化
localize({
  ja: {
    messages: {
      ...ja.messages,
    },
    names: {
      katakana: "カナ",
    },
    fields: {
      katakana: {
        regex: "カタカナで入力してください",
      },
    },
  },
});
localize("ja");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

export default {};
