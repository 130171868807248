// import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import jp from 'vuetify/es5/locale/zh-Hans'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
    values:{
      XIcon:{
        component: "XIcon",
      }
    }
  },
  theme: {
    options: {
      customProperties: true,
    },
    lang: {
      locales: { jp },
      current: 'jp',
    },
    themes: {
      light: {
        primary: "#ff5252",
        secondary: "#66beba",
        accent: "#efcd5d",
        error: "#dc5c4f",
        info: "#3a8dd1",
        success: "#51a57e",
        warning: "#ffc107",
        text: "#4c413e",
      },
    },
  },
});
