// 残高
const max_amount = {
  params: ["max"],

  validate(value, { max }) {
    return value <= max ? true : false;
  },

  message(field, { max }) {
    return `残高不足です。`;
  },
};

// 改行0半角0.5の文字数チェック
const max_digits = {
  params: ["max"],

  validate(value, { max }) {
    var result = 0;
    for (var i = 0; i < value.length; i++) {
      var chr = value.charCodeAt(i);
      if (chr === 0x000a || chr === 0x000d000a) {
        //改行は0
        continue;
      } else if (
        !(
          (
            chr === 91 || // '['
            chr === 93 || // ']'
            chr === 123 || // '{'
            chr === 125 || // '}'
            chr === 40 || // '('
            chr === 41 || // ')'
            chr === 65378 || // '｢'
            chr === 65379
          ) // '｣'
        ) &&
        ((chr >= 0x00 && chr < 0x81) || //英数字、句読点、制御文字
          chr === 0xf8f0 ||
          (chr >= 0xf8f1 && chr < 0xf8f4))
      ) {
        //半角0.5
        result += 0.5;
      } else {
        //それ以外1
        result += 1;
      }
    }

    return result ? result <= max : true;
  },

  message(field, { max }) {
    return `${field}は${max}字以下で入力してください。`;
  },
};

// 改行0半角0.5　＋　クロス台本用の文字数チェック
const max_digits_cross = {
  params: ["max"],

  validate(value, { max }) {
    var result = 0;

    const ary = value.split("\n"); //改行で切り分け
    let ary2 = [];
    ary.forEach((text) => ary2.push(text.replace(/^.+\：/, ""))); //行頭のキャラクター名を除外
    let ary3 = ary2.join("\n");

    for (var i = 0; i < ary3.length; i++) {
      var chr = ary3.charCodeAt(i);
      if (chr === 0x000a || chr === 0x000d000a) {
        //改行は0
        continue;
      } else if (
        !(
          (
            chr === 91 || // '['
            chr === 93 || // ']'
            chr === 123 || // '{'
            chr === 125 || // '}'
            chr === 40 || // '('
            chr === 41 || // ')'
            chr === 65378 || // '｢'
            chr === 65379
          ) // '｣'
        ) &&
        ((chr >= 0x00 && chr < 0x81) || //英数字、句読点、制御文字
          chr === 0xf8f0 ||
          (chr >= 0xf8f1 && chr < 0xf8f4))
      ) {
        //半角0.5
        result += 0.5;
      } else {
        //それ以外1
        result += 1;
      }
    }

    return result ? result <= max : true;
  },

  message(field, { max }) {
    return `${field}は${max}字以下で入力してください。`;
  },
};

const min_digits_replace = {
  params: ["min"],

  validate(value, { min }) {
    const str = value
      .replace(/《《(.+?)》》/g, "$1")
      .replace(/[\|｜](.+?)《(.+?)》/g, "$1")
      .replace(/[\|｜](.+?)（(.+?)）/g, "$1")
      .replace(/[\|｜](.+?)\((.+?)\)/g, "$1")
      .replace(/([一-龠]+)《(.+?)》/g, "$1")
      .replace(/[\|｜]《(.+?)》/g, "《$1》")
      .replace(/[\|｜]（(.+?)）/g, "（$1）")
      .replace(/[\|｜]\((.+?)\)/g, "($1)")
      .replace(/\n/g, "");
    var result = 0;
    for (var i = 0; i < str.length; i++) {
      var chr = str.charCodeAt(i);
      if (chr === 0x000a || chr === 0x000d000a) {
        //改行は0
        continue;
      } else if (
        !(
          (
            chr === 91 || // '['
            chr === 93 || // ']'
            chr === 123 || // '{'
            chr === 125 || // '}'
            chr === 40 || // '('
            chr === 41 || // ')'
            chr === 65378 || // '｢'
            chr === 65379
          ) // '｣'
        ) &&
        ((chr >= 0x00 && chr < 0x81) || //英数字、句読点、制御文字
          chr === 0xf8f0 ||
          (chr >= 0xf8f1 && chr < 0xf8f4))
      ) {
        //半角0.5
        result += 0.5;
      } else {
        //それ以外1
        result += 1;
      }
    }

    return result ? result >= min : true;
  },

  message(field, { min }) {
    return `${field}は${min}字以上で入力してください。`;
  },
};

const max_digits_replace = {
  params: ["max"],

  validate(value, { max }) {
    const str = value
      .replace(/《《(.+?)》》/g, "$1")
      .replace(/[\|｜](.+?)《(.+?)》/g, "$1")
      .replace(/[\|｜](.+?)（(.+?)）/g, "$1")
      .replace(/[\|｜](.+?)\((.+?)\)/g, "$1")
      .replace(/([一-龠]+)《(.+?)》/g, "$1")
      .replace(/[\|｜]《(.+?)》/g, "《$1》")
      .replace(/[\|｜]（(.+?)）/g, "（$1）")
      .replace(/[\|｜]\((.+?)\)/g, "($1)")
      .replace(/\n/g, "");
    var result = 0;
    for (var i = 0; i < str.length; i++) {
      var chr = str.charCodeAt(i);
      if (chr === 0x000a || chr === 0x000d000a) {
        //改行は0
        continue;
      } else if (
        !(
          (
            chr === 91 || // '['
            chr === 93 || // ']'
            chr === 123 || // '{'
            chr === 125 || // '}'
            chr === 40 || // '('
            chr === 41 || // ')'
            chr === 65378 || // '｢'
            chr === 65379
          ) // '｣'
        ) &&
        ((chr >= 0x00 && chr < 0x81) || //英数字、句読点、制御文字
          chr === 0xf8f0 ||
          (chr >= 0xf8f1 && chr < 0xf8f4))
      ) {
        //半角0.5
        result += 0.5;
      } else {
        //それ以外1
        result += 1;
      }
    }

    return result ? result <= max : true;
  },

  message(field, { max }) {
    return `${field}は${max}字以下で入力してください。`;
  },
};

//最少人数
const minmaxCharacter = {
  params: ["target"],
  validate(value, { target }) {
    return Number(value) <= Number(target);
  },
  message(field) {
    return `${field}は、最高人数より小さな数値を入力してください`;
  },
};

// 画像サイズ、以下
// import { RuleParamSchema } from '../types';
// declare const validate: (files: File | File[], { width, height }: Record<string, any>) => Promise<boolean>;
// declare const params: RuleParamSchema[];
// export { validate, params };
// declare const _default: {
//     validate: (files: File | File[], { width, height }: Record<string, any>) => Promise<boolean>;
//     params: RuleParamSchema[];
// };
// export default _default;

var validate$7 = function (files, _a) {
  var width = _a.width,
    height = _a.height;
  var list = [];
  files = Array.isArray(files) ? files : [files];
  for (var i = 0; i < files.length; i++) {
    // if file is not an image, reject.
    if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
      return Promise.resolve(false);
    }
    list.push(files[i]);
  }
  return Promise.all(
    list.map(function (file) {
      return validateImage(file, width, height);
    })
  ).then(function (values) {
    return values.every(function (v) {
      return v;
    });
  });
};

const dimensions_max = {
  params: ["w", "h"],

  validate(files, { w, h }) {
    var width = w,
      height = h;
    var list = [];
    files = Array.isArray(files) ? files : [files];
    for (var i = 0; i < files.length; i++) {
      // if file is not an image, reject.
      if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
        return Promise.resolve(false);
      }
      list.push(files[i]);
    }
    return Promise.all(
      list.map(function (file) {
        return validateImage(file, width, height);
      })
    ).then(function (values) {
      return values.every(function (v) {
        return v;
      });
    });
  },

  message(field, { w, h }) {
    return `${field}は横:${w}px、縦:${h}px以内でなければなりません`;
  },
};

var validateImage = function (file, width, height) {
  var URL = window.URL || window.webkitURL;
  return new Promise(function (resolve) {
    var image = new Image();
    image.onerror = function () {
      return resolve(false);
    };
    image.onload = function () {
      return resolve(image.width <= width && image.height <= height);
    };
    image.src = URL.createObjectURL(file);
  });
};

//width_height
const width_height = {
  params: ["width", "height", "srcWidth", "srcHeight"],
  validate(value, { width, height, srcWidth, srcHeight }) {
    return width === srcWidth && height === srcHeight;
  },
  message(field, { width, height, srcWidth, srcHeight }) {
    return `画像サイズが一致しません。横${width}✕縦${height}pxの画像を選択してください。`;
  },
};

//サイズをMB単位で
const size_mb = {
  params: ["mb"],

  validate(files, { mb }) {
    var size = mb;
    if (isNaN(size)) {
      return false;
    }
    var nSize = size * 1024 * 1024;
    if (!Array.isArray(files)) {
      return files.size <= nSize;
    }
    for (var i = 0; i < files.length; i++) {
      if (files[i].size > nSize) {
        return false;
      }
    }
    return true;
  },

  message(field, { mb }) {
    return `${field}は${mb}MB以内でなければなりません`;
  },
};

//半角鉤括弧
const disallowCornerBrackets = {
  validate: (value) => {
    for (let i = 0; i < value.length; i++) {
      let chr = value.charCodeAt(i);
      if (chr === 65378 || chr === 65379) {
        // '｢' or '｣'
        return false;
      }
    }
    return true;
  },
  message: "半角の角カギ括弧｢｣は使用しないでください",
};

// URL
const url = {
  validate(files) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+@]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(String(files));
  },

  message(field) {
    return `${field}は有効なURLではありません`;
  },
};

// ひらがな
const hiragana = {
  validate(value) {
    var pattern = /^[ぁ-ゖ！-／：-＠［-｀｛-～、-〜”’・ー 　]+$/;
    return pattern.test(String(value));
  },
  message(field) {
    return `${field}は「ひらがな」で入力してください`;
  },
};

// カナ
const kana = {
  validate(value) {
    var pattern = /^[ァ-ヶ！-／：-＠［-｀｛-～、-〜”’・ー 　]+$/;
    return pattern.test(String(value));
  },
  message(field) {
    return `${field}は「カタカナ」で入力してください`;
  },
};

// 絵文字
const emoji = {
  validate(value) {
    var pattern = /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g;
    // var pattern = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    return !pattern.test(String(value));
  },
  message(field) {
    return `${field}に絵文字が含まれています`;
  },
};

// 機種依存文字
const dependent = {
  validate(value) {
    var pattern = /^[ぁ-ゖァ-ヶ\u4E00-\u9FFF\u3005-\u3007a-zA-Zａ-ｚＡ-Ｚ0-9０-９Α-ωА-яⅠ-Ⅹ!-/:-@[-`{-~！-／：-＠［-｀｛-～、-〜”’・§†‡♯♭♪♡♧♤♢♥♣♠♦※…ー―‐ 　]+$/;
    return pattern.test(String(value));
  },
  message(field) {
    return `${field}に使用できない文字が含まれています`;
  },
};

// 機種依存文字＋改行
const dependentBr = {
  validate(value) {
    var pattern = /^[ぁ-ゖァ-ヶ\u4E00-\u9FFF\u3005-\u3007a-zA-Zａ-ｚＡ-Ｚ0-9０-９Α-ωА-яⅠ-Ⅹ!-/:-@[-`{-~！-／：-＠［-｀｛-～、-〜”’・§†‡♯♭♪♡♧♤♢♥♣♠♦※…ー―‐ 　\n\r]+$/;
    return pattern.test(String(value));
  },
  message(field) {
    return `${field}に使用できない文字が含まれています`;
  },
};

export {
  max_amount,
  max_digits,
  max_digits_cross,
  min_digits_replace,
  max_digits_replace,
  dimensions_max,
  size_mb,
  width_height,
  minmaxCharacter,
  disallowCornerBrackets,
  url,
  emoji,
  dependent,
  dependentBr,
  hiragana,
  kana,
};
